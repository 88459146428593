body {
  background-color: #f9fafe;
  color: #4a63a1;
  font: 16px/1.2 "Raleway", "Arial", "Helvetica Neue", "Helvetica", sans-serif;
}

.App {
  overflow-x: hidden;
  margin: 10px 30px;

  h1 {
    text-align: center;
    margin: 10px 0 10px 0;
  }

  .clear .input-group-text:hover {
    background-color: #cccccc;
  }
}
