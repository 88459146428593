.account {
  .title {
    font-weight: bold;
    font-size: 14px;
  }

  .value {
    font-style: italic;
    font-size: 14px;
  }

  .row {
    padding: 5px 0px;
  }

  table.users {
    .actions {
      width: 5rem;
    }
  }

  .react-dual-listbox {
    height: 20rem;
  }

  .usage {
    padding-top: 10px;
    height: 20rem;
    .react-datepicker__input-container {
      input {
        width: 85px;
      }
    }
  }
  .change {
    padding-top: 10px;
    .react-datepicker__input-container {
      input {
        width: 85px;
      }
    }
  }

  .button-plan {
    margin: 0 5px 0 5px;
  }
}

.addFeatures {
  .info {
    font-weight: 600;
    padding: 10px 5px;
  }
  .elements {
    font-style: italic;
  }
}

.account-credit {
  .bold {
    font-weight: bold;
  }
  .modify {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
    align-self: center;
  }
  .modify:hover {
    color: #0056b3;
    text-decoration: underline;
    cursor: pointer;
  }
}
