.admin {
  padding-top: 10px;
  span {
    padding: 10px 20px 10px 0px;
  }
  .admin-block {
    border: 1px solid #dee2e6;
    border-radius: 6px;
    min-width: 500px;
    margin-top: 15px;
    background-color: #f1f3fc;
  }
  .button-plan {
    min-height: 60px;
  }
}
