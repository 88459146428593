.accounts {
  padding-top: 10px;
  table {
    .id {
      width: 8rem;
    }
    .name {
      width: 35rem;
    }
    .date {
      width: 25rem;
    }
    .subscriptionId {
      width: 20rem;
    }
  }

  form {
    .title {
      font-weight: bold;
    }
  }
}

.disabled {
  color: #babfc4;
}
